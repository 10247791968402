/**
 * @generated SignedSource<<395669391b53a035b8abef6e3c08da51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DelegateDialogFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly " $fragmentSpreads": FragmentRefs<"NounResolvedNameFragment">;
    };
  };
  readonly delegate: {
    readonly delegatedVotesRaw: any;
    readonly nounsRepresented: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
    }>;
  } | null;
  readonly " $fragmentType": "DelegateDialogFragment";
};
export type DelegateDialogFragment$key = {
  readonly " $data"?: DelegateDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DelegateDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DelegateDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounResolvedNameFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Delegate",
      "kind": "LinkedField",
      "name": "delegate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delegatedVotesRaw",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            }
          ],
          "concreteType": "Noun",
          "kind": "LinkedField",
          "name": "nounsRepresented",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounImageFragment"
            }
          ],
          "storageKey": "nounsRepresented(first:60)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WrappedDelegate",
  "abstractKey": null
};

(node as any).hash = "53b372f857e7aec72306adcdef7ab998";

export default node;
