/**
 * @generated SignedSource<<fa328c07a0f1d70abb7b147709caafb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterPanelDelegateFromListFragment$data = {
  readonly tokenHoldersRepresented: ReadonlyArray<{
    readonly address: {
      readonly resolvedName: {
        readonly " $fragmentSpreads": FragmentRefs<"NounResolvedLinkFragment">;
      };
    };
    readonly id: string;
    readonly nouns: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
    }>;
    readonly tokenBalance: any;
  }>;
  readonly " $fragmentType": "VoterPanelDelegateFromListFragment";
};
export type VoterPanelDelegateFromListFragment$key = {
  readonly " $data"?: VoterPanelDelegateFromListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterPanelDelegateFromListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterPanelDelegateFromListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "tokenHoldersRepresented",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tokenBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ResolvedName",
              "kind": "LinkedField",
              "name": "resolvedName",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NounResolvedLinkFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Noun",
          "kind": "LinkedField",
          "name": "nouns",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounImageFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};
})();

(node as any).hash = "cb5d978a0bb88336b4e279f2ef5e5718";

export default node;
