/**
 * @generated SignedSource<<936d69153ab25727ab0eda40a3f4b5bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NounGridFragment$data = {
  readonly delegatedVotesRaw: any;
  readonly nounsRepresented: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
  }>;
  readonly " $fragmentType": "NounGridFragment";
};
export type NounGridFragment$key = {
  readonly " $data"?: NounGridFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NounGridFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NounGridFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "delegatedVotesRaw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 60
        }
      ],
      "concreteType": "Noun",
      "kind": "LinkedField",
      "name": "nounsRepresented",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NounImageFragment"
        }
      ],
      "storageKey": "nounsRepresented(first:60)"
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "8ce6dfa824141193084d47e793ae1a34";

export default node;
