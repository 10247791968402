/**
 * @generated SignedSource<<e4b43224a0d73a360259ea7feee80e0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterCardFragment$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"NounResolvedNameFragment">;
    };
  };
  readonly delegate: {
    readonly delegatedVotesRaw: any;
    readonly id: string;
    readonly nounsRepresented: ReadonlyArray<{
      readonly __typename: "Noun";
    }>;
    readonly voteSummary: {
      readonly totalVotes: number;
    };
  } | null;
  readonly statement: {
    readonly statement: string;
    readonly summary: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"VoterCardDelegateProfileImage" | "VoterPanelActionsFragment">;
  readonly " $fragmentType": "VoterCardFragment";
};
export type VoterCardFragment$key = {
  readonly " $data"?: VoterCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterCardFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoterPanelActionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoterCardDelegateProfileImage"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NounResolvedNameFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateStatement",
      "kind": "LinkedField",
      "name": "statement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Delegate",
      "kind": "LinkedField",
      "name": "delegate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delegatedVotesRaw",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            }
          ],
          "concreteType": "Noun",
          "kind": "LinkedField",
          "name": "nounsRepresented",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": "nounsRepresented(first:60)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DelegateVotesSummary",
          "kind": "LinkedField",
          "name": "voteSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalVotes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WrappedDelegate",
  "abstractKey": null
};

(node as any).hash = "5b23c4e23ef235361e5882a0f55ec8b6";

export default node;
