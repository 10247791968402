/**
 * @generated SignedSource<<f92754651136002b0349f0a900ac8686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterPanelQueryFragment$data = {
  readonly currentGovernance: {
    readonly delegatedVotes: any;
    readonly proposals: any;
  };
  readonly metrics: {
    readonly quorumVotesBPS: any;
  };
  readonly recentProposals: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "VoterPanelQueryFragment";
};
export type VoterPanelQueryFragment$key = {
  readonly " $data"?: VoterPanelQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterPanelQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterPanelQueryFragment",
  "selections": [
    {
      "alias": "recentProposals",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "createdBlock"
        },
        {
          "kind": "Literal",
          "name": "orderDirection",
          "value": "desc"
        }
      ],
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": "proposals(first:10,orderBy:\"createdBlock\",orderDirection:\"desc\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Governance",
      "kind": "LinkedField",
      "name": "currentGovernance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proposals",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delegatedVotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverallMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quorumVotesBPS",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "44f1a436411be488a150c99491ded8aa";

export default node;
