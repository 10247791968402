/**
 * @generated SignedSource<<fed42b13ee7e8d0d8b259aae50e8ca04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NounImageFragment$data = {
  readonly number: number;
  readonly seed: {
    readonly accessory: any;
    readonly background: any;
    readonly body: any;
    readonly glasses: any;
    readonly head: any;
  } | null;
  readonly " $fragmentType": "NounImageFragment";
};
export type NounImageFragment$key = {
  readonly " $data"?: NounImageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NounImageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NounImageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seed",
      "kind": "LinkedField",
      "name": "seed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessory",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "background",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "glasses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "head",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Noun",
  "abstractKey": null
};

(node as any).hash = "334debeeef7449bbf8c4e985c0ff85c8";

export default node;
