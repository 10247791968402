/**
 * @generated SignedSource<<ec25d4ec3e41ddfb986e244f2a29276d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDelegatePageQuery$variables = {
  address: string;
};
export type EditDelegatePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DelegateStatementFormFragment">;
};
export type EditDelegatePageQuery = {
  response: EditDelegatePageQuery$data;
  variables: EditDelegatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditDelegatePageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DelegateStatementFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditDelegatePageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "addressOrEnsName",
            "variableName": "address"
          }
        ],
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isContract",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ResolvedName",
            "kind": "LinkedField",
            "name": "resolvedName",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WrappedDelegate",
            "kind": "LinkedField",
            "name": "wrappedDelegate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DelegateStatement",
                "kind": "LinkedField",
                "name": "statement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statement",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Proposal",
                    "kind": "LinkedField",
                    "name": "mostValuableProposals",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Proposal",
                    "kind": "LinkedField",
                    "name": "leastValuableProposals",
                    "plural": true,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discord",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopIssue",
                    "kind": "LinkedField",
                    "name": "topIssues",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "openToSponsoringProposals",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "allProposals",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "createdBlock"
          },
          {
            "kind": "Literal",
            "name": "orderDirection",
            "value": "desc"
          }
        ],
        "concreteType": "Proposal",
        "kind": "LinkedField",
        "name": "proposals",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": "proposals(first:1000,orderBy:\"createdBlock\",orderDirection:\"desc\")"
      }
    ]
  },
  "params": {
    "cacheID": "0b1b7d503135f94196a9b79db41e42dc",
    "id": null,
    "metadata": {},
    "name": "EditDelegatePageQuery",
    "operationKind": "query",
    "text": "query EditDelegatePageQuery(\n  $address: String!\n) {\n  ...DelegateStatementFormFragment_2WA0fu\n}\n\nfragment DelegateStatementFormFragment_2WA0fu on Query {\n  address(addressOrEnsName: $address) {\n    isContract\n    resolvedName {\n      address\n      name\n    }\n    wrappedDelegate {\n      statement {\n        statement\n        mostValuableProposals {\n          number\n          id\n        }\n        leastValuableProposals {\n          number\n          id\n        }\n        discord\n        twitter\n        topIssues {\n          type\n          value\n        }\n        openToSponsoringProposals\n      }\n      id\n    }\n  }\n  ...PastProposalsFormSectionProposalListFragment\n}\n\nfragment PastProposalsFormSectionProposalListFragment on Query {\n  allProposals: proposals(first: 1000, orderDirection: desc, orderBy: createdBlock) {\n    id\n    number\n    title\n  }\n}\n"
  }
};
})();

(node as any).hash = "1db0391113017ac668f7e7878990d5c4";

export default node;
