/**
 * @generated SignedSource<<4a19f932bc2db3dfa6aa0505c11cd866>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterPanelDelegateButtonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DelegateDialogFragment">;
  readonly " $fragmentType": "VoterPanelDelegateButtonFragment";
};
export type VoterPanelDelegateButtonFragment$key = {
  readonly " $data"?: VoterPanelDelegateButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterPanelDelegateButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterPanelDelegateButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DelegateDialogFragment"
    }
  ],
  "type": "WrappedDelegate",
  "abstractKey": null
};

(node as any).hash = "39dd80bb65841edd66c4d10d85e337f7";

export default node;
