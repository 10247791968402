/**
 * @generated SignedSource<<d7da4ee40f395e1946d8278ccf120220>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageHeaderQuery$variables = {
  address: string;
  skip: boolean;
};
export type PageHeaderQuery$data = {
  readonly address?: {
    readonly wrappedDelegate: {
      readonly statement: {
        readonly __typename: "DelegateStatement";
      } | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PageHeaderFragment">;
  } | null;
};
export type PageHeaderQuery = {
  response: PageHeaderQuery$data;
  variables: PageHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addressOrEnsName",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DelegateStatement",
  "kind": "LinkedField",
  "name": "statement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WrappedDelegate",
                "kind": "LinkedField",
                "name": "wrappedDelegate",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PageHeaderFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageHeaderQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WrappedDelegate",
                "kind": "LinkedField",
                "name": "wrappedDelegate",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tokenBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Noun",
                    "kind": "LinkedField",
                    "name": "nouns",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Seed",
                        "kind": "LinkedField",
                        "name": "seed",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessory",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "background",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "body",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "glasses",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "head",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "eca6d9f17e71e45ddd13af2604bde880",
    "id": null,
    "metadata": {},
    "name": "PageHeaderQuery",
    "operationKind": "query",
    "text": "query PageHeaderQuery(\n  $address: String!\n  $skip: Boolean!\n) {\n  address(addressOrEnsName: $address) @skip(if: $skip) {\n    wrappedDelegate {\n      statement {\n        __typename\n      }\n      id\n    }\n    ...PageHeaderFragment\n  }\n}\n\nfragment NounImageFragment on Noun {\n  number\n  seed {\n    accessory\n    background\n    body\n    glasses\n    head\n    id\n  }\n}\n\nfragment PageHeaderFragment on Address {\n  account {\n    tokenBalance\n    nouns {\n      id\n      ...NounImageFragment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d85b0ec806a8f39b4a51bab561d81b0a";

export default node;
