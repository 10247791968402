/**
 * @generated SignedSource<<ef422950e9118acd411a3959a694ff32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImpactfulProposalsFragment$data = {
  readonly leastValuableProposals: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ImpactfulProposalsProposalFragment">;
  }>;
  readonly mostValuableProposals: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ImpactfulProposalsProposalFragment">;
  }>;
  readonly " $fragmentType": "ImpactfulProposalsFragment";
};
export type ImpactfulProposalsFragment$key = {
  readonly " $data"?: ImpactfulProposalsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImpactfulProposalsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ImpactfulProposalsProposalFragment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImpactfulProposalsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "mostValuableProposals",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "leastValuableProposals",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DelegateStatement",
  "abstractKey": null
};
})();

(node as any).hash = "a8fdbaa1c0b65485d2176c687d4e45a0";

export default node;
