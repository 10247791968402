/**
 * @generated SignedSource<<d900f56ed4d6f05ac6d9a80591d86a25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewMetricsContainer$data = {
  readonly currentGovernance: {
    readonly currentDelegates: any;
    readonly currentTokenHolders: any;
    readonly delegatedVotesRaw: any;
  };
  readonly metrics: {
    readonly proposalThresholdBPS: any;
    readonly quorumVotesBPS: any;
  };
  readonly recentlyCompletedProposals: ReadonlyArray<{
    readonly createdBlockGovernance: {
      readonly delegatedVotes: any;
    };
    readonly totalVotes: any;
  }>;
  readonly " $fragmentType": "OverviewMetricsContainer";
};
export type OverviewMetricsContainer$key = {
  readonly " $data"?: OverviewMetricsContainer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewMetricsContainer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewMetricsContainer",
  "selections": [
    {
      "alias": "recentlyCompletedProposals",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "createdBlock"
        },
        {
          "kind": "Literal",
          "name": "orderDirection",
          "value": "desc"
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status_in": [
              "QUEUED",
              "EXECUTED",
              "PENDING",
              "ACTIVE"
            ]
          }
        }
      ],
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Governance",
          "kind": "LinkedField",
          "name": "createdBlockGovernance",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "delegatedVotes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "proposals(first:10,orderBy:\"createdBlock\",orderDirection:\"desc\",where:{\"status_in\":[\"QUEUED\",\"EXECUTED\",\"PENDING\",\"ACTIVE\"]})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverallMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quorumVotesBPS",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proposalThresholdBPS",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Governance",
      "kind": "LinkedField",
      "name": "currentGovernance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delegatedVotesRaw",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentTokenHolders",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentDelegates",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "853438a0eb12b776b771314bec9d7e64";

export default node;
