/**
 * @generated SignedSource<<0b1bc2cc4aebc606494ff67528b414c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterCardDelegateProfileImage$data = {
  readonly address: {
    readonly resolvedName: {
      readonly address: string;
    };
  };
  readonly delegate: {
    readonly nounsRepresented: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"NounGridFragment">;
  } | null;
  readonly " $fragmentType": "VoterCardDelegateProfileImage";
};
export type VoterCardDelegateProfileImage$key = {
  readonly " $data"?: VoterCardDelegateProfileImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterCardDelegateProfileImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterCardDelegateProfileImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Delegate",
      "kind": "LinkedField",
      "name": "delegate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 60
            }
          ],
          "concreteType": "Noun",
          "kind": "LinkedField",
          "name": "nounsRepresented",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": "nounsRepresented(first:60)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NounGridFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WrappedDelegate",
  "abstractKey": null
};

(node as any).hash = "592d63e00f2b0202411e55f68bd0f953";

export default node;
