/**
 * @generated SignedSource<<342c4b8088077187f817251aa4b47907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteDetailsFragment$data = {
  readonly createdAt: any | null;
  readonly proposal: {
    readonly number: number;
    readonly title: string;
    readonly totalValue: any;
  };
  readonly reason: string | null;
  readonly supportDetailed: number;
  readonly votes: any;
  readonly " $fragmentType": "VoteDetailsFragment";
};
export type VoteDetailsFragment$key = {
  readonly " $data"?: VoteDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoteDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoteDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportDetailed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "votes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Proposal",
      "kind": "LinkedField",
      "name": "proposal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Vote",
  "abstractKey": null
};

(node as any).hash = "12f10d7a91a39acf10b80c8d23ced0d3";

export default node;
