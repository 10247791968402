/**
 * @generated SignedSource<<3529db46fb40ae23af5289efc09f5fae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterPanelDelegateFragment$data = {
  readonly resolvedName: {
    readonly " $fragmentSpreads": FragmentRefs<"VoterPanelNameSectionFragment">;
  };
  readonly wrappedDelegate: {
    readonly delegate: {
      readonly delegatedVotes: any;
      readonly id: string;
      readonly proposals: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly voteSummary: {
        readonly abstainVotes: number;
        readonly againstVotes: number;
        readonly forVotes: number;
      };
      readonly votes: ReadonlyArray<{
        readonly id: string;
        readonly proposal: {
          readonly id: string;
        };
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"VoterPanelDelegateFromListFragment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"VoterCardDelegateProfileImage" | "VoterPanelActionsFragment">;
  };
  readonly " $fragmentType": "VoterPanelDelegateFragment";
};
export type VoterPanelDelegateFragment$key = {
  readonly " $data"?: VoterPanelDelegateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterPanelDelegateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterPanelDelegateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResolvedName",
      "kind": "LinkedField",
      "name": "resolvedName",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VoterPanelNameSectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WrappedDelegate",
      "kind": "LinkedField",
      "name": "wrappedDelegate",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VoterCardDelegateProfileImage"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VoterPanelActionsFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Delegate",
          "kind": "LinkedField",
          "name": "delegate",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "delegatedVotes",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VoterPanelDelegateFromListFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DelegateVotesSummary",
              "kind": "LinkedField",
              "name": "voteSummary",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "forVotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "againstVotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "abstainVotes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "blockNumber"
                },
                {
                  "kind": "Literal",
                  "name": "orderDirection",
                  "value": "desc"
                }
              ],
              "concreteType": "Vote",
              "kind": "LinkedField",
              "name": "votes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Proposal",
                  "kind": "LinkedField",
                  "name": "proposal",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": "votes(orderBy:\"blockNumber\",orderDirection:\"desc\")"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Proposal",
              "kind": "LinkedField",
              "name": "proposals",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Address",
  "abstractKey": null
};
})();

(node as any).hash = "b43f1e9e5fce9fe8ae26f17cac3bdafa";

export default node;
