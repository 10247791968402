/**
 * @generated SignedSource<<acc732bc6f253a186d93ad79ad0739e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DelegateStatementFormFragment$data = {
  readonly address: {
    readonly isContract: boolean;
    readonly resolvedName: {
      readonly address: string;
      readonly name: string | null;
    };
    readonly wrappedDelegate: {
      readonly statement: {
        readonly discord: string;
        readonly leastValuableProposals: ReadonlyArray<{
          readonly number: number;
        }>;
        readonly mostValuableProposals: ReadonlyArray<{
          readonly number: number;
        }>;
        readonly openToSponsoringProposals: boolean | null;
        readonly statement: string;
        readonly topIssues: ReadonlyArray<{
          readonly type: string;
          readonly value: string;
        }>;
        readonly twitter: string;
      } | null;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PastProposalsFormSectionProposalListFragment">;
  readonly " $fragmentType": "DelegateStatementFormFragment";
};
export type DelegateStatementFormFragment$key = {
  readonly " $data"?: DelegateStatementFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DelegateStatementFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DelegateStatementFormFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "addressOrEnsName",
          "variableName": "address"
        }
      ],
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isContract",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResolvedName",
          "kind": "LinkedField",
          "name": "resolvedName",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WrappedDelegate",
          "kind": "LinkedField",
          "name": "wrappedDelegate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DelegateStatement",
              "kind": "LinkedField",
              "name": "statement",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "statement",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Proposal",
                  "kind": "LinkedField",
                  "name": "mostValuableProposals",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Proposal",
                  "kind": "LinkedField",
                  "name": "leastValuableProposals",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discord",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "twitter",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TopIssue",
                  "kind": "LinkedField",
                  "name": "topIssues",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "openToSponsoringProposals",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PastProposalsFormSectionProposalListFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b9e8ff2fc1c9ca5af5af6fdee83621ff";

export default node;
